<template>
    <div class="card">
        <div class="bg-blue-light p-1">
            <TitleButton 
                :showBtn="true"
                :showAddNew="true"
                :title="'Patient Register > IPD'"
                btnTitle="New Bill"
                :showExtraField="true"
                @onClickCloseButton="closeButton"
            />
        </div>

        <div class="p-2">
            <DateQuerySetter
                btnTitle="Go"
                :isVisible="true"
            >
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <label for="colFormLabel" class="col-form-label">Select Doctor: </label>
                    <v-select 
                        placeholder="Select Consultant"
                    />
                </div>
            </DateQuerySetter>
        </div>

        <div class="col-12 px-2 mt-2">
            <ListTable :tableItems="tableItems"/>
        </div>
    </div>
</template>

<script setup>
    import TitleButton from '@/components/atom/TitleButtonHospital';
    import DateQuerySetter from '@/components/atom/DateQuerySetter';
    import ListTable from '@/components/molecule/company/hospital/IPDListTable.vue';
    import {useRoute, useRouter} from "vue-router";

    const $route = useRoute();
    const $router = useRouter();
    const tableItems = [{admissionNo: 'IPD-2023-101', word: 2, date: '11 Jan 2023 10 AM', consultantName: 'Mokhtar Ali', status: 'Completed', attachments: '', medicineOrder: 12, investOrder: 2}];

    const closeButton = () => {
        $router.back();
    }
</script>