<template>
    <div class="card" style="min-height: 200px">
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th>Admission No</th>
                    <th>Admission Date & Time</th>
                    <th>Ward / Cabin</th>
                    <th>Consultant</th>
                    <th>Status</th>
                    <th>OT Order</th>
                    <th>Doctor's Order</th>
                    <th>Discharge</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, i) in tableItems" :key="i">
                    <td> {{ item.admissionNo }}</td>
                    <td> {{ item.date }}</td>
                    <td> {{ item.word }}</td>
                    <td> {{ item.consultantName }}</td>
                    <td> {{ item.status }}</td>
                    <td> <a href="">View OT Order</a></td>
                    <td>
                        <button class="btn btn-primary btn-sm"
                                style="cursor: pointer"
                        >
                          <i class="fas fa-eye"></i>
                        </button>
                    </td>
                    <td><a href="">Discharge Certificate</a></td>
                </tr>

                </tbody>
            </table>
            <p v-if="!tableItems.length" class="text-center mt-5">No data</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ListTable',

        props: {
            tableItems: Array
        }
    }
</script>